import React, { useEffect, useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSeedling,
    faSmile,
    faThumbsUp,
    faHeart,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// COMPONENTS IMPORT
import { BackToTop } from "./features/back-to-top/BackToTop";
import { Navigationbar } from "./features/navbar/Navigationbar";
import { VarietyCarousel } from "./features/variety-carousel/VarietyCarousel";
import { ProductCard } from "./features/product-card/ProductCard";
import { Map } from "./features/map/Map";

// IMAGES IMPORT
const banner = require("./images/sfondo/lowres_banner.png");
const logoAgriom = require("./images/partners/logo_agriom.jpeg");
const logoSunnyCities = require("./images/partners/logo_sunny_cities_new.png");
const logoMba = require("./images/partners/logo_mba.jpg");

// CAROUSEL DATA IMPORT
const Hibiscus = require("./data/sunny-cities-carousel.json");
const Mandevilla = require("./data/summerstar-carousel.json");

// PRODUCT CARDS DATA IMPORT
const products = require("./data/products.json");

function App() {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setShowButton(window.scrollY > 100);
        });
    }, []);

    return (
        <div className="App">
            {/* Hero Section */}
            <section className="hero" id="title">
                <Container fluid style={{ paddingTop: "0px" }}>
                    {/* Nav Bar */}
                    <Navigationbar />

                    <Row>
                        <Col lg={6} className="banner-texts">
                            <h1 className="big-heading">
                                quality young plants
                            </h1>
                            <p className="banner-paragraph">
                                Here at Piante Keicher we love growing young
                                plants{" "}
                                <FontAwesomeIcon
                                    className="hero-p-icon"
                                    icon={faHeart}
                                    style={{ fontSize: "1rem" }}
                                />
                                <br /> We strive to deliver every day the best
                                possible products to our clients.
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img
                                className="title-image"
                                src={banner}
                                alt="hibiscus-sunny-cities-banner"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Features Section */}
            <section className="white-section" id="features">
                <div className="container-fluid">
                    <div className="feature-main-text">
                        <h2>A company with strong roots</h2>
                        <br />
                        <p style={{ color: "black" }}>
                            Piante Keicher, located in the heart of "Agro
                            Pontino", Italy, was founded in 1985 by Dieter
                            Keicher as a pot plant production company. The firm
                            transitioned over the last years to a new phase, by
                            shifting its main focus to production of young
                            plants, always maintaining at its core the
                            principles, the experience and the know-how acquired
                            during its 30+ years history. The company joined in
                            2014 the "Hibiscus Working Group", a group of
                            companies pursuing the goal of always offering new
                            and beautiful Hibiscus selections, sold under the
                            brand name of Sunny Cities <sup>®</sup>. The close
                            cooperation over the years with the Dutch breeder
                            Agriom, also responsible for the breeding program of
                            HWG, and the Mandevilla Breeders Association, led
                            more recently to the introduction of the Mandevilla
                            Summerstar <sup>®</sup> series in the firm's
                            assortment.
                        </p>
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-lg-4 feature-box">
                            <FontAwesomeIcon
                                className="features-img"
                                icon={faSeedling}
                                style={{ fontSize: "3rem" }}
                            />
                            <h3 className="feature-title">A robust start.</h3>
                            <p className="features-p">
                                Healthy and vigorous young plants are the
                                perfect ingredients to the recipe of a
                                successful crop.
                            </p>
                        </div>
                        <div className="col-lg-4 feature-box">
                            <FontAwesomeIcon
                                className="features-img"
                                icon={faSmile}
                                style={{ fontSize: "3rem" }}
                            />
                            <h3 className="feature-title">Grower friendly.</h3>
                            <p className="features-p">
                                Our varieties are selected in order to make your
                                work as easy as possible by having an overall
                                uniform behaviour within the same line.
                            </p>
                        </div>
                        <div className="col-lg-4 feature-box">
                            <FontAwesomeIcon
                                className="features-img"
                                icon={faThumbsUp}
                                style={{ fontSize: "3rem" }}
                            />
                            <h3 className="feature-title">Great results.</h3>
                            <p className="features-p">
                                You will love them and your clients too. The
                                consistency of our selections will also make
                                their life easier.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Hibiscus Sunny Cities Section */}
            <VarietyCarousel article={Hibiscus} />

            {/* Mandevilla Summerstar Section */}
            <VarietyCarousel article={Mandevilla} />

            {/* Products */}
            <section className="colored-section" id="products">
                <h3 className="section-heading">
                    Great young plants for every grower's needs!
                </h3>
                <p>
                    Beautiful varieties delivered at different growth stages to
                    better fit in your company's production flow.
                </p>
                <div className="row">
                    {/* Unrooted cuttings */}
                    <div className="products-column col-lg-4 col-md-6">
                        <ProductCard
                            product={products.unrootedCuttings}
                        ></ProductCard>
                    </div>
                    {/* Rooted Cuttings */}
                    <div className="products-column col-lg-4 col-md-6">
                        <ProductCard
                            product={products.rootedCuttings}
                        ></ProductCard>
                    </div>
                    {/* Half Finished Plants */}
                    <div className="products-column col-lg-4 col-md12">
                        <ProductCard
                            product={products.halfFinished}
                        ></ProductCard>
                    </div>
                </div>
            </section>

            {/* Partners Section */}
            <section className="white-section" id="partners">
                <h5>Our business partners:</h5>
                <a
                    href="https://agriom.nl/en/homepage/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="partners-logo"
                        src={logoAgriom}
                        alt="agriom-logo"
                    />
                </a>
                <a
                    href="https://hibiscus-sunnycities.nl/en/homepage/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="partners-logo"
                        src={logoSunnyCities}
                        alt="sunny-cities-logo"
                    />
                </a>
                <a
                    href="https://agriom.nl/en/crops/mandevilla-2/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="partners-logo"
                        src={logoMba}
                        alt="mba-logo"
                    />
                </a>
            </section>

            {/* Footer Section */}
            <footer className="colored-section" id="footer">
                <div className="container-fluid">
                    {/* <iframe
                        title="map"
                        className="googleMap"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47867.81358601374!2d12.972108738250734!3d41.423198173011095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7666f64a182977f!2sSocietà Agricola Piante Keicher S.R.L!5e0!3m2!1sit!2sit!4v1674486006287!5m2!1sit!2sit"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe> */}
                    <Map></Map>
                    <p>
                        Società Agricola Piante Keicher s.r.l. - Strada del
                        Malconsiglio 1642, 04100 B.go Grappa, Latina (LT), Italy
                    </p>
                    <p>
                        email <FontAwesomeIcon icon={faEnvelope} />{" "}
                        <a href="mailto:info@piantekeicher.com">
                            info@piantekeicher.com
                        </a>
                    </p>
                    <p>© Copyright 2023 - Soc. Agr. Piante Keicher s.r.l.</p>
                    <hr />
                    <p>Designed & Developed by Daniel Keicher</p>
                </div>
            </footer>

            {showButton && <BackToTop />}
        </div>
    );
}

export default App;
