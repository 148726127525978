import React, { lazy } from "react";
import styles from "./ProductCard.module.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export function ProductCard(props) {
    const images = require.context("../../images", true);

    return (
        <Card>
            <Card.Header className={styles.header}>
                <h4>{props.product.title}</h4>
            </Card.Header>
            <Card.Body className={styles.card}>
                {/* IMAGES NOT AVAILABLE YET, THIS SECTION NEEDS TO BE ADDED LATER */}
                {/* <Card.Img
                    src={images(`./${props.product.src}`)}
                    alt={props.product.alt}
                ></Card.Img> */}
                <Card.Text className={styles.text}>
                    <span className={styles.mainPerk}>
                        {props.product.perk1}
                    </span>
                    <br />
                    <span>{props.product.perk2}</span>
                    <br />
                    <span>{props.product.perk3}</span>
                    <br />
                    <span>{props.product.perk4}</span>
                </Card.Text>
                <Button className={styles.button}>
                    <a
                        href="mailto:info@piantekeicher.com"
                        className="btn btn-lg btn-block btn-outline-light"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faEnvelope} /> learn more...
                    </a>
                </Button>
            </Card.Body>
        </Card>
    );
}
