import React from "react";
import styles from "./Map.module.css";

export function Map() {
    return (
        <iframe
            title="map"
            className={styles.googleMap}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47867.81358601374!2d12.972108738250734!3d41.423198173011095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7666f64a182977f!2sSocietà Agricola Piante Keicher S.R.L!5e0!3m2!1sit!2sit!4v1674486006287!5m2!1sit!2sit"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    );
}
