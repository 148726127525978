import React, { lazy, useState } from "react";
import styles from "./VarietyCarousel.module.css";
import Carousel from "react-bootstrap/Carousel";

export function VarietyCarousel(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const article = props.article;
    const images = require.context("../../images", true);

    return (
        <div>
            <section className={article.sectionClass} id={article.sectionId}>
                <div
                    className={`container-fluid ${article.titleClass}`}
                    dangerouslySetInnerHTML={{ __html: article.title }}
                ></div>
                <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    variant={article.carouselVariant}
                >
                    {article.varieties.map((v) => (
                        <Carousel.Item
                            className={styles.carouselItem}
                            key={v.key}
                        >
                            <img
                                className={article.imgClass}
                                src={images(`./${v.src}`)}
                                alt={v.alt}
                                loading="lazy"
                            />
                            <Carousel.Caption
                                className={styles.carouselCaption}
                            >
                                <h4 className={article.varietyClass}>
                                    {v.name}
                                </h4>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </section>
        </div>
    );
}
