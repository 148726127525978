import React from "react";
import styles from "./Navigationbar.module.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// IMAGES IMPORT
const pianteKeicherLogo = require("../../images/piante_keicher_logo.png");

export function Navigationbar() {
    return (
        <Navbar className={styles.navBar} expand="lg">
            <Container>
                <Navbar.Brand className={styles.navLogo} href="/">
                    <img
                        className="piante-keicher-logo"
                        src={pianteKeicherLogo}
                        alt="piante-keicher-logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className={styles.navList}>
                        <Nav.Link className={styles.navLink} href="#features">
                            about
                        </Nav.Link>
                        <Nav.Link className={styles.navLink} href="#hibiscus">
                            hibiscus
                        </Nav.Link>
                        <Nav.Link className={styles.navLink} href="#mandevilla">
                            mandevilla
                        </Nav.Link>
                        <Nav.Link className={styles.navLink} href="#products">
                            products
                        </Nav.Link>
                        <Nav.Link className={styles.navLink} href="#partners">
                            partners
                        </Nav.Link>
                        <Nav.Link className={styles.navLink} href="#footer">
                            contacts
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
