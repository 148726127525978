import React from "react";
import styles from "./BackToTop.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

export function BackToTop() {
    return (
        <div>
            <div
                id="return-to-top"
                className={styles.returnToTop}
                onClick={() => {
                    document.location.href = "#title";
                }}
            >
                <FontAwesomeIcon
                    className={styles.chevronUpIcon}
                    icon={faChevronUp}
                />
            </div>
        </div>
    );
}
